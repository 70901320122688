/* eslint-env browser */

import 'jquery';
$.htmlPrefilter = function (html) {
  return html;
};

import '@polydice/icook-navbar';
import '@polydice/icook-facebook-init-js/dist/sdk';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/alert';
import 'bootstrap/js/transition';
import 'bootstrap/js/collapse';
import 'slick-carousel';
import ujs from '@rails/ujs';

ujs.start();
